import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-layout',
    templateUrl: './auth-layout.component.html',
    styleUrls: ['./auth-layout.component.scss']
})

export class AuthLayoutComponent implements OnInit {

    constructor() {}

    ngOnInit() {}

}
