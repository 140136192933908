import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './app.auth';

import { LayoutComponent } from './layout/layout.component';
import { AuthLayoutComponent } from './layout/auth-layout.component';

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './structure/home/home.module#HomeModule'
            },
            {
                path: 'users',
                loadChildren: './structure/users/users.module#UsersModule'
            },
            {
                path: 'board',
                loadChildren: './structure/board/board.module#BoardModule'
            },
            {
                path: 'equipments',
                loadChildren: './structure/equipments/equipments.module#EquipmentsModule'
            },
            {
                path: 'rental',
                loadChildren: './structure/rental/rental.module#RentalModule'
            },
            {
                path: 'payment',
                loadChildren: './structure/payment/payment.module#PaymentModule'
            },
            {
                path: 'education-support',
                loadChildren: './structure/education-support/education-support.module#EducationSupportModule'
            },
            {
                path: 'technical-support',
                loadChildren: './structure/technical-support/technical-support.module#TechnicalSupportModule'
            },
            {
                path: 'partners',
                loadChildren: './structure/partners/partners.module#PartnersModule'
            },
            {
                path: 'sleep-place',
                loadChildren: './structure/users/users.module#UsersModule',
                canActivate: [AuthGuard],
                runGuardsAndResolvers: 'always'
            },
            {
                path: 'community',
                loadChildren: './structure/settings/settings.module#SettingsModule'
            },
            {
                path: 'settings',
                loadChildren: './structure/settings/settings.module#SettingsModule'
            },
            {
                path: 'blank',
                loadChildren: './structure/blank/blank.module#BlankModule'
            },
        ]
    },
    {
        path: 'auth',
        component: AuthLayoutComponent,
        children: [{
            path: 'session',
            loadChildren: './structure/session/session.module#SessionModule'
        }]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
            useHash: true
        })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
