import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MessagesComponent } from './messages/messages.component';

@NgModule({
    declarations: [
        MessagesComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        MessagesComponent
    ]
})
export class SharedModule { }
