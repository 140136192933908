import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class InteractionService {

    private subjects: Subject<any>[] = [];

    send(eventName: string) {
        this.subjects[eventName] = this.subjects[eventName] || new Subject();
        this.subjects[eventName].next();

        console.log("send>>>", this.subjects[eventName])
    }

    on(eventName: string): Observable<any> {
        this.subjects[eventName] = this.subjects[eventName] || new Subject();

        console.log("on>>>", this.subjects[eventName])
        return this.subjects[eventName].asObservable();
    }

}
