import { Component, OnInit, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location, PlatformLocation } from '@angular/common';
import { AppGlobals } from '../app.globals';
import { UserService } from '../user.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { EventEmitter } from 'events';
import { url } from 'inspector';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    providers: [ UserService ]
})

export class LayoutComponent implements OnInit {
    pageTitle = '';
    navitems = this.globals.configs.menu;

    userId:string;
    userName:string;

    mouseOverMenuNo: number;
    selectedMenuNo: number;
    mouseOverSubMenuNo: number;
    selectedSubMenuNo: number;

    mySubscription: any;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private globals: AppGlobals,
        private dataService: UserService,
        private titleService: Title,
        private route: ActivatedRoute,
        private location: PlatformLocation,
    ) {
    }
    
    ngOnInit() {
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).pipe(
            map(() => this.activatedRoute)
        ).pipe(
            map((route) => {
                while (route.firstChild) { route = route.firstChild; }
                return route;
            })
        ).pipe(
            filter((route) => route.outlet === 'primary')
        ).pipe(
            mergeMap((route) => route.data)
        )
        .subscribe((event) => {
            this.titleService.setTitle(event.title);
            this.pageTitle = event.title;
            let urlSplit=this.router.url.split("/");
            if(urlSplit.length>3&&urlSplit[3]!="null"){
                this.globals.offset = Number(urlSplit[3])-1;
            }
            if(urlSplit[1]!=""){
                let a = this.globals.subMenu.filter(v=>v.src=="/"+urlSplit[1]+"/"+urlSplit[2]);
                if(a.length>0){
                    this.globals.panelTitle=a[0].name;
                }
            }
        });
        for(var i=0; i<this.navitems.length; i++){
            for(var j=0; j<this.navitems[i].sub.length; j++){
                this.globals.subMenu.push(this.navitems[i].sub[j]);
            }
        }
        let urlSplit=this.router.url.split("/");
        if(urlSplit.length>3&&urlSplit[3]!="null"){
            this.globals.offset = Number(urlSplit[3])-1;
        }
        if(urlSplit[1]!=""){
            let a = this.globals.subMenu.filter(v=>v.src=="/"+urlSplit[1]+"/"+urlSplit[2]);
            if(a.length>0){
                this.globals.panelTitle=a[0].name;
            }
        }

        this.hoverCtrl();

        this.location.onPopState(()=>{
            this.route.params.subscribe(params=>{
            })
 
            this.router.navigateByUrl('/', {
                skipLocationChange: true,
              }).then(
                () => {
                    this.router.navigateByUrl(this.router.url);
                }
              );
                this.hoverCtrl();
        })

        if(sessionStorage.getItem('currentUserId') && sessionStorage.getItem('currentUserName')){
            this.globals.userId=sessionStorage.getItem('currentUserId');
            this.globals.userName=sessionStorage.getItem('currentUserName');
            
            this.userId=this.globals.userId;
            this.userName=this.globals.userName;
        }else{
            this.Logout();
        }
    }

    onPaging(event){
        console.log("in onPaging", event)
        let pageSet=event.offset+1;
        let pageUrl = this.router.url;
        let pageSplit = pageUrl.split("/");
        if(pageSplit[3]){
            pageUrl=pageSplit.slice(0,-1).join("/")+"/"+pageSet;
            console.log("in pageUrl", pageUrl)
        }
        this.router.navigateByUrl(pageUrl);
    }

    changePageUrl(page){
        let pageUrl = this.router.url;
        let pageSplit = pageUrl.split("/");
        if(pageSplit[3]){
            pageUrl=pageSplit.slice(0,-1).join("/") + "/" + page;
            console.log("in pageUrl", pageUrl)
        }
        this.router.navigateByUrl(pageUrl);

    }

    urlChange(){
        let urlSlice = this.router.url.split("/");
        let urlSet = "";
        if(urlSlice[6]=="edit"){
            urlSet = urlSlice.slice(0,-1).slice(0,-1).slice(0,-1).join("/");
        }else if(urlSlice[4]=="view"){
            urlSet = urlSlice.slice(0,-1).slice(0,-1).join("/");
        }else{
            urlSet = urlSlice.join("/");
        }
        this.router.navigateByUrl(urlSet)
        window.scroll(0, 0);
    }

    hoverCtrl(){
        setTimeout(() => {
            let urlSplit=this.router.url.split("/");
            if(urlSplit.length>2){
                    let a = this.globals.subMenu.filter(v=>v.src=="/"+urlSplit[1]+"/"+urlSplit[2]);
                    if(a.length>0){
                        this.selectedMenuNo=a[0].no.slice(0,1);
                        this.mouseOverMenuNo=this.selectedMenuNo;
                        this.mouseOverSubMenuNo=a[0].no;
                        this.selectedSubMenuNo=this.mouseOverSubMenuNo;
                    }
            }else{
                if(!this.globals.userId && !this.globals.userName){
                    this.router.navigate(['/auth/session']);
                }
                this.selectedMenuNo = 0;
                this.mouseOverMenuNo = 0;
                this.mouseOverSubMenuNo = 0;
            }

        }, 500);
    }

    Logout() {
        this.dataService.logout();
        this.router.navigate(['/auth/session']);
    }

    routerLink(s){
        this.globals.menuSrc = s.split('/')[2];

        this.router.navigateByUrl('/', {
            skipLocationChange: true,
        }).then(
            () => {
                this.router.navigateByUrl(s);
            }
        );

    }


}
