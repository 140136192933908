import { Injectable } from '@angular/core';

@Injectable()
export class AppGlobals {
    constructor() { }

    // public serverUrl = 'http://eq-test-go.innest.co.kr';
    // public serverUrl = 'http://kakasiro7-equipments-lease-go.innest.co.kr';

    // real
    public serverUrl = 'https://equipments-lease-go.innest.co.kr';

    public userId = '';
    public userName = '';
    public userPosition = '';
    public menuSrc = '';
    public menuName = '';
    public subMenu = [];
    public panelTitle = '';
    public userPermission = null;
    public isDevIP = false;
    public membershipType = '';

    public keyCheck = [{"key":"Y", "value":"있음"}, {"key":"N", "value":"없음"}];
    public rentalSt = [{"key":"1", "value":"계약중"},{"key":"2", "value":"계약종료"},{"key":"-1", "value":"계약해지"}];
    public userType = [{"key":"B", "value":"기업회원"},{"key":"S", "value":"학생회원"},{"key":"C", "value":"일반회원"},{"key":"P", "value":"교수회원"},{"key":"E", "value":"전문가회원"}];

    public offset = 0;
    public limit = 1000;

    public configs = null;
    public tDate: string = this.convertDate();

    // 그리드 높이
    public gridHeight: number = window.innerHeight - 360;

    // 그리드 메세지
    public datatableMessages = { emptyMessage: '<div class="no-data">검색된 데이터가 없습니다.</div>', totalMessage: 'total' };

    // 권한없음 메세지
    public isNotExecutable = '실행 권한이 없습니다.';
    public isNotPrintable = '인쇄 권한이 없습니다.';


    convertDate() {
        const t = new Date();
        const y = t.getFullYear();
        const m = t.getMonth() + 1;
        const d = t.getDate();

        return y + '-' + this.numberToString(m) + '-' + this.numberToString(d);
    }

    numberToString(n) {
        let str = '';
        if (n < 10) {
            str = '0' + n;
        } else {
            str = String(n);
        }
        return str;
    }

    addComma(n) {
        if (n < 1000) {
            return n;
        }
        return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    removeComma(n) {
        if ( !n ) {
            return 0;
        }
        if (n < 1000) {
            return n;
        }
        return n.replace(/[^\d.-]/g, '') * 1;
    }



}
