import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, BehaviorSubject, Subject, throwError } from 'rxjs';
import { catchError, tap, switchMap, take, map } from 'rxjs/operators';
import { AppGlobals } from 'src/app/app.globals';
import { Item, Mail, Recipients, BoardFolder } from './board.items';

const httpOptions = {};

@Injectable()
export class BoardService {
    // Observables
    // private _category: BehaviorSubject<MailCategory>;
    // private _filters: BehaviorSubject<MailFilter[]>;
    private _folders: BehaviorSubject<BoardFolder[]>;
    // private _labels: BehaviorSubject<MailLabel[]>;
    private _mails: BehaviorSubject<Mail[]>;
    // private _mailsLoading: BehaviorSubject<boolean>;
    private _mail: BehaviorSubject<Mail>;
    // private _pagination: BehaviorSubject<any>;

    // Observables that can be executable from outside
    selectedMailChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     */
    constructor(
        private http: HttpClient,
        private globals: AppGlobals
    ) {
        // Set the private defaults
        // this._category = new BehaviorSubject(null);
        // this._filters = new BehaviorSubject(null);
        this._folders = new BehaviorSubject(null);
        // this._labels = new BehaviorSubject(null);
        this._mails = new BehaviorSubject(null);
        // this._mailsLoading = new BehaviorSubject(false);
        this._mail = new BehaviorSubject(null);
        // this._pagination = new BehaviorSubject(null);

        // Set the defaults
        this.selectedMailChanged = new BehaviorSubject(null);
    }

    private url = this.globals.serverUrl + '/board';

    // BoardController
    // 데이터 조회
    GetAll(code, params): Observable<Item[]> {
        return this.http.get<Item[]>(this.url + '/' + code, {params});
    }

    // 메일그룹 조회
    GetAllMailGroup(): Observable<Item[]> {
        return this.http.get<Item[]>(this.url + '/all-mail-group', {});
    }

    // 회원 데이터 조회
    GetAllUser(code, params): Observable<Item[]> {
        return this.http.get<Item[]>(this.url + '/user-list/' + code, {params});
    }

    // POST: 데이터 추가
    Create(code, data: Item): Observable<Item> {
        return this.http.post<Item>(this.url + '/' + code, data, httpOptions).pipe(
            // tslint:disable-next-line: no-shadowed-variable
            tap((data: Item) => this.log(`added data w/ id=${data}`)),
            catchError(this.handleError<Item>('Create'))
        );
    }

    // POST: 메일그룹 추가
    CreateMailGroup(data: Item): Observable<Item> {
        return this.http.post<Item>(this.url + '/mail-group-c', data, httpOptions).pipe(
            tap((data: Item) => this.log(`added data w/ id=${data}`)),
            catchError(this.handleError<Item>('Create'))
        );
    }

    // POST: 메일그룹에 수신자 추가
    CreateUsersMailGroup(data: Item): Observable<Item> {
        return this.http.post<Item>(this.url + '/users-mail-group-c', data, httpOptions).pipe(
            tap((data: Item) => this.log(`added data w/ id=${data}`)),
            catchError(this.handleError<Item>('Create'))
        );
    }

    UploadFile(code, atcId, data) {
        return this.http.post(this.url + '/' + code + '/' + atcId + '/upload', data, httpOptions)
    }

    DeleteFile(code, atcId: number, params): Observable<Item> {
        return this.http.delete<Item>(this.url + '/' + code + '/' + atcId + '/delete', {params});
    }

    // POST: 발송 이메일 기록 데이터 추가
    SendEmail(atcId, id): Observable<Item> {
        return this.http.post<Item>(this.globals.serverUrl + '/mail/' + atcId + '/send-email/' + id , httpOptions).pipe(
            // tslint:disable-next-line: no-shadowed-variable
            tap((data: Item) => this.log(`added data w/ id=${data}`)),
            catchError(this.handleError<Item>('Create'))
        );
    }

    GetById(id): Observable<Item> {
        return this.http.get<Item>(this.url + '/article/' + id);
    }

    // 포토갤러리, 홍보동영상 전용
    GetByIdG(code, id): Observable<Item> {
        return this.http.get<Item>(this.url + '/g/' + code + '/' + id);
    }
    
    GetById2(boardId, id): Observable<Item> {
        return this.http.get<Item>(this.url + "/" + boardId + "/" + id);
    }

    //  PUT: 데이터 수정
    Update(code, id: number, data: Item): Observable<Item> {
        return this.http.put<Item>(this.url + '/' + code + '/'+ id, data, httpOptions);
    }

    // DELETE: 데이터 삭제
    Delete(code, id: number): Observable<Item> {
        return this.http.delete<Item>(this.url + '/' + code + '/' + id, httpOptions);
    }

    // DELETE: 메일그룹 삭제
    DeleteMailGroup(params): Observable<Item> {
        return this.http.delete<Item>(this.url + '/mail-group-d', {params});
    }

    // DELETE: 메일그룹 등록 유저 삭제
    DeleteUsersMailGroup(params): Observable<Item> {
        console.log('test delete conf', params)
        return this.http.delete<Item>(this.url + '/users-mail-group-d', {params});
    }

    // DELETE: 등록된 수신자 삭제
    DeleteMailRecipients(params): Observable<Item> {
        console.log('DeleteMailRecipients service', params)
        return this.http.delete<Item>(this.globals.serverUrl + '/mail/mail-recipients-tmp', {params});
    }

    // SendMailController
    // 데이터 조회
    GetRecipientsTmpAll(params): Observable<Recipients[]> {
        return this.http.get<Recipients[]>(this.globals.serverUrl + '/mail/recipients-tmp', {params});
    }

    // POST: 데이터 추가
    CreateRecipients(atcId, data: Item): Observable<Item> {
        console.log("data", data)
        return this.http.post<Item>(this.globals.serverUrl + '/mail/recipients/'+atcId, data, httpOptions).pipe(
            // tslint:disable-next-line: no-shadowed-variable
            tap((data: Item) => this.log(`added data w/ id=${data}`)),
            catchError(this.handleError<Item>('Create'))
        );
    }

    // 뉴스레터 search-users -> MembersController
    GetAllCnt(code, params): Observable<Item[]> {
        return this.http.get<Item[]>(this.globals.serverUrl + '/members/cnt/' + code, {params});
    }

    // 메일그룹 등록된 수신자 -> MembersController
    GetAllNewsLetterUsers(params): Observable<Item[]> {
        return this.http.get<Item[]>(this.globals.serverUrl + '/members/users/', {params});
    }

    // 실패한 Http 작업 처리
    // 프로그램은 계속 실행되도록 함.
    // @param operation - 실패한 작업명
    // @param result - observable 결과로 리턴할 선택적 값

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: 원격 로깅 인프라에 에러 전송
            console.error(error); // 대신 콘솔에 로그 출력

            // TODO: 오류 변환을 더 좋게 처리
            this.log(`${operation} failed: ${error.message}`);

            // 프로그램이 계속 실행되도록 빈 결과를 리턴
            return of(result as T);
        };
    }

    /** 오류 로그 */
    private log(message: string) {
        console.log(message);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for folders
     */
    get folders$(): Observable<BoardFolder[]> {
        return this._folders.asObservable();
    }

    /**
     * Getter for mails
     */
    get mails$(): Observable<Mail[]> {
        return this._mails.asObservable();
    }

    /**
     * Getter for mail
     */
    get mail$(): Observable<Mail> {
        return this._mail.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get folders
     */
    getFolders(): Observable<any> {
        return this.http.get<BoardFolder[]>(this.url + '/filter/folders').pipe(
            tap((response: any) => {
                this._folders.next(response);
            })
        );
    }

    /**
     * Get mails by folder
     */
    getMailsByFolder(folder: string, page: string = '1'): Observable<any> {
        // Execute the mails loading with true
        // this._mailsLoading.next(true);

        return this.http.get<Mail[]>('api/apps/mailbox/mails', {
            params: {
                folder,
                page
            }
        }).pipe(
            tap((response: any) => {
                // console.log("getMailsByFilter", response)
                // this._category.next({
                //     type: 'folder',
                //     name: folder
                // });
                this._mails.next(response.mails);
                // this._pagination.next(response.pagination);
                // this._mailsLoading.next(false);
            }),
            switchMap((response) => {

                if ( response.mails === null ) {
                    return throwError({
                        message   : 'Requested page is not available!',
                        pagination: response.pagination
                    });
                }

                return of(response);
            })
        );
    }

    /**
     * Get mail by id
     */
    getMailById(id: string): Observable<any> {
        return this._mails.pipe(
            take(1),
            map((mails) => {

                // Find the mail
                const mail = mails.find(item => item.id === id) || null;

                // Update the mail
                this._mail.next(mail);

                // Return the mail
                return mail;
            }),
            switchMap((mail) => {

                if ( !mail ) {
                    return throwError('Could not found mail with id of ' + id + '!');
                }

                return of(mail);
            })
        );
    }

    /**
     * Update mail
     *
     */
    updateMail(id: string, mail: Mail): Observable<any> {
        return this.http.patch('api/apps/mailbox/mail', {
            id,
            mail
        }).pipe(
            tap(() => {

                // Re-fetch the folders on mail update
                // to get the updated counts on the sidebar
                this.getFolders().subscribe();
            })
        );
    }

    /**
     * Reset the current mail
     */
    resetMail(): Observable<boolean> {
        return of(true).pipe(
            take(1),
            tap(() => {
                this._mail.next(null);
            })
        );
    }
}
