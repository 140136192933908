import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AppRoutingModule } from './app-routing.module';

// Global Variables
import { AppGlobals } from './app.globals';

// Auth
import { AuthGuard } from './app.auth';

// Layout Components
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { AuthLayoutComponent } from './layout/auth-layout.component';

// Shared Services
import { SharedModule } from './structure/shared/shared.module';
import { ConfigService } from './config.service';
import { InteractionService } from './interaction.service';
import { MessageService } from './message.service';

// NG Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BoardService } from './structure/board/board.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// RECOMMENDED
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

const ROUTES = [
];

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    AuthLayoutComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (HttpLoaderFactory),
          deps: [HttpClient]
      }
    }),
  ],
  providers: [
    AppGlobals,
    ConfigService,
    InteractionService,
    BoardService,
    {
        provide: APP_INITIALIZER,
        useFactory: (configService: ConfigService) => () => configService.load(),
        deps: [ConfigService],
        multi: true
    },
    AuthGuard,
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
